<template>
  <base-section
    id="k-d-a-technology-card"
    :class="isOddNumber ? 'background-tech-list' : 'white'"
    top-inner-shadow
    space="80"
  >
    <v-container class="pa-0 my-10 my-md-16">
      <v-row no-gutters :class="g_bLowerBr && isOddNumber ? 'col-reverse' : 'row-wrapper'">
        <v-col v-if="g_bHighestBr" lg="1" />
        <v-col v-else-if="g_bBaseBr" lg="1" />
        <v-col v-else-if="g_bHigherBr" lg="1" />
        <v-col
          v-if="isOddNumber"
          cols="12"
          :lg="`${g_bHighestBr ? 4 : g_bBaseBr ? 4 : 4}`"
          :md="`${g_bHighestBr ? 4 : g_bBaseBr ? 4 : 4}`"
          style="flex-direction: column; display: flex; align-items: center"
        >
          <div class="img-container center">
            <img
              :src="imgTechnology"
              :class="g_bLowestBr || g_bBaseBr ? 'top' : 'right'"
              style="object-fit: contain"
            />
          </div>
        </v-col>
        <v-col
          v-if="!isOddNumber"
          cols="12"
          :lg="`${g_bHighestBr ? 5 : g_bBaseBr ? 5 : 5}`"
          :md="`${g_bHighestBr ? 5 : g_bBaseBr ? 5 : 5}`"
          :class="`px-${g_bLowerBr || g_bBaseBr ? 4 : 0}`"
          style="
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <div>
            <div :class="`kda-ts-${g_bLowerBr ? 28 : 46}pt wt-extrabold`">{{ strTechnology }}</div>
            <div :class="`kda-ts-${g_bLowerBr ? 12 : 18}pt nunito wt-regular mt-4 mt-md-4`">
              {{ strDescription }}
            </div>
            <div class="center mt-4 mt-md-4 row-wrapper cursor-pointer" @click="navToDetailTech">
              <v-btn icon elevation="1" class="main-bw-300 mr-3">
                <v-icon color="white">mdi-arrow-right</v-icon>
              </v-btn>
              <div
                :class="`kda-ts-${g_bLowerBr ? 12 : 16}pt nunito wt-semibold`"
                style="align-self: center"
              >
                {{ strLink }}
              </div>
            </div>
          </div>
        </v-col>

        <v-col v-if="g_bHighestBr" lg="1" />
        <v-col v-else-if="g_bBaseBr" lg="1" />
        <v-col v-else-if="g_bHigherBr" lg="1" />
        <v-col
          v-if="!isOddNumber"
          cols="12"
          :lg="`${g_bHighestBr ? 4 : g_bBaseBr ? 4 : 4}`"
          :md="`${g_bHighestBr ? 4 : g_bBaseBr ? 4 : 4}`"
          style="flex-direction: column; display: flex; align-items: center"
        >
          <div class="img-container center">
            <img
              :src="imgTechnology"
              :class="g_bLowestBr || g_bBaseBr ? 'top' : 'right'"
              style="object-fit: contain"
            />
          </div>
        </v-col>
        <v-col
          v-if="isOddNumber"
          cols="12"
          :lg="`${g_bHighestBr ? 5 : g_bBaseBr ? 5 : 5}`"
          :md="`${g_bHighestBr ? 5 : g_bBaseBr ? 5 : 5}`"
          :class="`px-${g_bLowerBr || g_bBaseBr ? 4 : 0}`"
          style="
            flex-direction: column;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <div>
            <div :class="`kda-ts-${g_bLowerBr ? 28 : 46}pt wt-extrabold`">{{ strTechnology }}</div>
            <div :class="`kda-ts-${g_bLowerBr ? 12 : 18}pt nunito wt-regular mt-4 mt-md-4`">
              {{ strDescription }}
            </div>
            <div class="center mt-4 mt-md-4 row-wrapper cursor-pointer" @click="navToDetailTech">
              <v-btn icon elevation="1" class="main-bw-300 mr-3">
                <v-icon color="white">mdi-arrow-right</v-icon>
              </v-btn>
              <div
                :class="`kda-ts-${g_bLowerBr ? 12 : 16}pt nunito wt-semibold`"
                style="align-self: center"
              >
                {{ strLink }}
              </div>
            </div>
          </div>
        </v-col>

        <v-col v-if="g_bHighestBr" lg="1" />
        <v-col v-else-if="g_bBaseBr" lg="1" />
        <v-col v-else-if="g_bHigherBr" lg="1" />
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDATechnologyCard',

  mixins: [BaseBreakpoint],

  props: {
    strTechnology: {
      type: String,
      default: ''
    },
    strDescription: {
      type: String,
      default: ''
    },
    strLink: {
      type: String,
      default: ''
    },
    numIndex: {
      type: Number,
      default: 0
    },
    imgTechnology: {
      type: String,
      default: ''
    },
    strLinkDetail: {
      type: String,
      default: ''
    }
  },

  computed: {
    isOddNumber() {
      return this.numIndex % 2 === 1;
    }
  },

  methods: {
    navToDetailTech() {
      this.$router.push(`detail/${this.$props.strLinkDetail}`);
    }
  }
};
</script>

<style scoped lang="scss">
@import 'src/styles/variables.scss';

.col-reverse {
  display: flex !important;
  flex-direction: column-reverse !important;
}

.row-wrapper {
  display: flex;
  flex-direction: row;
}

.background-tech-list {
  background-color: #fbfbfb;
}

.cursor-pointer {
  cursor: pointer;
}

.img-container {
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  img {
    &.right {
      height: 460px;
      width: 460px;
    }

    &.top {
      height: fit-content;
      width: 290px;
      position: relative;
      margin-top: 50px;
    }
  }
}
</style>
